import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAccessToken } from "../../hooks/AccessToken";
import Constant from "../Constant";
import "./UsersPage.css";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(0); // Total pages available
  const [totalCount, setTotalCount] = useState(0); // Total users count
  const itemsPerPage = 10; // Default items per page

  useEffect(() => {
    const fetchUsers = async () => {
      const token = getAccessToken();
      try {
        setLoading(true);
        const url = searchQuery
          ? `${Constant.apiBasePath}getAll?search=${encodeURIComponent(searchQuery)}&page=${page}&limit=${itemsPerPage}`
          : `${Constant.apiBasePath}getAll?page=${page}&limit=${itemsPerPage}`;

        const response = await axios.post(url, {}, {
          headers: { token },
        });

        if (response.data.status === "Success") {
          setUsers(response.data.data);
          setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage)); // Calculate total pages
          setTotalCount(response.data.totalCount); // Update total user count
        } else {
          setError("Failed to fetch users.");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [searchQuery, page]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(search);
    setPage(1); // Reset to first page for new search
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  if (error) return <p className="error-message">Error: {error}</p>;

  return (
    <div className="users-page">
      <h1 className="header">User List</h1>

      {/* Search Form */}
      <form onSubmit={handleSearch} className="search-form">
        <input
          type="text"
          placeholder="Search by name, email, or mobile"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="search-input"
        />
        <button type="submit" className="search-button">
          Search
        </button>
      </form>

      {/* User Table */}
      <div className="table-container">
        <table className="users-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user, index) => (
                <tr key={user._id}>
                  <td>{(page - 1) * itemsPerPage + index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{new Date(user.createdAt).toLocaleString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="pagination-controls">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="pagination-button"
          >
            Previous
          </button>
          <span className="pagination-info">
            Page {page} of {totalPages} ({totalCount} users)
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
            className="pagination-button"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default UsersPage;
